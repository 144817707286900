<template>
  <v-layout>
    <div class="all-content">
      <navbar-component />
      <div
        class="global-content"
        :style="{ height: screenHeight + 'px' }"
        @click="closeMenuResponsive()"
      >
        <router-view
          id="GlobalContent"
          :style="{ height: screenHeight + 'px' }"
          :class="
            this.$route.name !== 'PressAndNewsDetail' || isMobile !== true
              ? 'content-router'
              : 'content-router-detail-press-and-news'
          "
        />
      </div>

      <!-- <div class="demoColorResponsive"></div> -->
    </div>
    <v-overlay :value="bOverlay">
      <div class="content-loading-logout">
        <v-img
          class="loading-logout"
          src="../assets/ImgsLogin/Icon-Login.png"
          max-height="95"
          min-height="95"
          max-width="100"
        ></v-img>
      </div>
    </v-overlay>
  </v-layout>
</template>

<script>
import NavbarComponent from "../global-components/NavbarComponent.vue";
export default {
  name: "Home",
  data() {
    return {
      isMobile: false,
      screenWidth: 0,
      screenHeight: 0,
      screenHeightRouter: 0,
    };
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  created() {
    this.setOverlayHome(this.bSidebar);
  },
  methods: {
    onResize() {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight - 70;
      this.isMobile = 600 >= window.innerWidth;
    },
    setOverlayHome(bSidebar) {
      this.$nextTick(() => {
        if (bSidebar) {
          var GlobalContent1 = document.getElementById("GlobalContent");
          GlobalContent1.classList.add("global-content-overlay");
        } else {
          var GlobalContent2 = document.getElementById("GlobalContent");
          GlobalContent2.classList.remove("global-content-overlay");
        }
      });
    },
    closeMenuResponsive() {
      if (this.bSidebar) {
        this.$store.commit("setSidebar", false);
      }
    },
  },
  computed: {
    bSidebar() {
      return this.$store.state.bSidebar;
    },
    bOverlay() {
      return this.$store.state.bOverlay;
    },
  },
  watch: {
    bSidebar() {
      this.setOverlayHome(this.bSidebar);
    },
    bOverlay() {
      if (this.bOverlay) {
        let layoutClass = document.getElementsByClassName("all-content");
        layoutClass[0].classList.add("global-content-overlay");
      }
    },
  },
  components: { NavbarComponent },
};
</script>
<style scoped>
.global-content {
}
.global-content-overlay {
  /* padding: 4px 84px !important; */
  filter: blur(4px) !important;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*#region content-router */

.content-router {
  overflow: auto;
  padding: 4px 84px !important;
}

.content-router::-webkit-scrollbar {
  height: 0px;
  width: 5px;
}

.content-router::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.content-router::-webkit-scrollbar-thumb {
  background: var(--primary-color-scroll);

  border-radius: 10px;
}

.content-router::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-scroll);
}

/*#endregion  content-router*/

/*#region content-router-detail-press-and-news */

.content-router-detail-press-and-news {
  overflow-x: auto;
  /* padding: 4px 84px !important; */
}

.content-router-detail-press-and-news::-webkit-scrollbar {
  height: 0px;
  width: 5px;
}

.content-router-detail-press-and-news::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.content-router-detail-press-and-news::-webkit-scrollbar-thumb {
  background: var(--primary-color-scroll);

  border-radius: 10px;
}

.content-router-detail-press-and-news::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-scroll);
}

/*#endregion  content-router-detail-press-and-news*/

.content-overlay {
  opacity: 0.46;
  background-color: rgb(33, 33, 33);
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  z-index: 8px !important;
}

.content-loading-logout {
  animation: pulse 1s infinite;
}

.loading-logout {
  position: relative;
}

.loading-logout:before,
.loading-logout:after {
  transform: rotate(-45deg);
  transform-origin: 0 100%;
  animation: pulsecolor 1s infinite;
}

.loading-logout:after {
  transform: rotate(45deg);
  transform-origin: 100% 100%;
  animation: pulsecolor 1s infinite;
}

@keyframes pulse {
  10% {
    transform: scale(1.1);
  }
}

@keyframes pulsecolor {
  10% {
    background: #dd0000;
  }
}

/* RESPONSIVE CSS */
.demoColorResponsive {
  position: absolute;
  height: 1vh;
  width: 10vh;
  background-color: rgb(0, 0, 0);
  bottom: 0px;
  right: 50%;
  left: 50%;
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-router {
    overflow: auto;
    padding: 4px 20px !important;
  }
  .content-search-press-and-news-details {
    padding: 4px 20px !important;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(120, 136, 621);
  }
  .global-content {
    /* padding: 4px 44px !important; */
  }
  .content-router {
    overflow: auto;
    padding: 4px 44px !important;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
