<template>
  <div>
    <press-and-news-component />
  </div>
</template>
<script>
import PressAndNewsComponent from "../components/press_and_news/PressAndNewsComponent.vue";
export default {
  components: { PressAndNewsComponent },
  data() {
    return {};
  },
};
</script>
<style scoped>
</style>