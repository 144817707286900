<template>
  <div class="content-search-press-and-news-details">
    <div class="content-icon-arrow-left">
      <v-btn
        @click="back()"
        small
        class="btn-backgorund-E3E3E3"
        color="black"
        icon
      >
        <v-icon> mdi-chevron-left </v-icon>
      </v-btn>
    </div>
    <div class="content-text">
      <div id="ContentTextEdit" class="content-text-edit">
        {{ TextCapitalLetter("ContentTextEdit", sAction + " registro") }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sAction: String,
  },
  data() {
    return {};
  },
  methods: {
    TextCapitalLetter(idElement, text) {
      let aText = text.split(" ");
      let sText = " ";

      for (let i = 0; i < aText.length; i++) {
        const element = aText[i];
        sText += `<p
                class="${
                  element.length > 1
                    ? `text-capital-letter-edit ${
                        i < aText.length - 1 ? `mr-2` : ``
                      }`
                    : `text-only-one-letter-edit ${
                        i < aText.length - 1 ? `mr-2` : ``
                      } `
                }"

              >
                ${element}
              </p>`;
      }
      this.$nextTick(() => {
        let element = document.getElementById(idElement);
        element.innerHTML = sText;
      });
      return sText;
    },
    back() {
      this.$router.push({ name: "PressAndNews" });
    },
  },
};
</script>
<style scoped>
.content-search-press-and-news-details {
  display: flex;
  margin-top: 15px !important;
  width: fit-content;
  /* background-color: brown; */
}
.content-icon-arrow-left {
  display: flex;
  align-items: center;
  width: 40px;
  max-width: 40px;
  min-width: 40px;
}
.content-text {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}
.content-text-edit {
  display: flex;
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }


  .content-search-press-and-news-details {
    padding: 4px 22px !important;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(120, 136, 621);
  }

}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>