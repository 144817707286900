<template>
  <div>
    <v-app-bar class="contant-navbar" :min-width="screenWidth" :max-width="screenWidth" height="70" max-height="70"
      min-height="70" color="white">
      <div class="content-navbar-inside">
        <div class="content-logo">
          <v-img :class="isMobile ? 'img-log-navbar-mobile' : 'img-log-navbar'" max-width="270"></v-img>
        </div>
        <div class="content-text">
          <div @click="goView('press-and-news')" :class="
            this.$route.name === 'PressAndNews' ? '' : 'item-not-selected'
          " class="content-text-press-news">
           <p class="text-capital-letter" v-show="isLap" :class="
              this.$route.name === 'PressAndNews' || this.$route.name === 'PressAndNewsDetail' ? '' : 'item-not-selected'
            ">
              Prensa
            </p>
             <p class="text-and ml-1 mr-1" v-show="isLap" :class="
              this.$route.name === 'PressAndNews' || this.$route.name === 'PressAndNewsDetail' ? '' : 'item-not-selected'
            ">
              &
            </p>
            <p class="text-capital-letter mr-5" v-show="isLap" :class="
              this.$route.name === 'PressAndNews' || this.$route.name === 'PressAndNewsDetail' ? '' : 'item-not-selected'
            ">
              Noticias
            </p>
            <div v-show="isLap" :class="
              this.$route.name === 'PressAndNews' || this.$route.name === 'PressAndNewsDetail'
                ? 'content-selected-item-press-news'
                : ''
            "></div>
          </div>
          <v-divider vertical v-show="isLap"></v-divider>
          <div @click="goView('contact')" class="content-text-contact">
            <p :class="this.$route.name === 'Contact' ? '' : 'item-not-selected'" class="text-capital-letter ml-5"
              v-show="isLap">
              Reseñas
            </p>
            <div v-show="isLap" :class="
              this.$route.name === 'Contact'
                ? 'content-selected-item-contact'
                : ''
            "></div>
          </div>
        </div>
        <div class="content-profile">
          <v-menu rounded="xl" left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="content-menu-profile" v-show="isTable || isLap" v-bind="attrs" v-on="on"
                @click="$store.commit('setSidebar', false)">
                <v-avatar>
                  <img src="@/assets/ImgsNavbar/Img-Profile-2.png" />
                </v-avatar>
                <v-icon>mdi-chevron-down</v-icon>
              </div>
            </template>
            <v-list class="list-menu-profile">
              <v-list-item v-for="(item, index) in items" :key="index" @click="Logout()">
                <v-list-item-icon class="item-icon">
                  <v-icon>mdi-power</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <div class="content-item-text">
                    <p class="item-text mr-2">Cerrar</p>
                    <p class="item-text">Sesión</p>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn @click="openMenuResponsive" icon v-show="isMobile || isTable">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </div>
      </div>
    </v-app-bar>

    <div v-show="isMobile || isTable">
      <navbar-responsive-component :bOpenMenu="bOpenMenu" @Logout="Logout" @goView="goView"
        v-show="isMobile || isTable" />
    </div>
  </div>
</template>
<script>
import NavbarResponsiveComponent from "./NavbarResponsiveComponent.vue";
export default {
  components: { NavbarResponsiveComponent },
  data() {
    return {
      overlay: false,
      screenWidth: 0,
      screenHeight: 0,
      isMobile: false,
      isTable: false,
      isLap: false,
      drawer: false,
      group: null,
      items: [{ title: "Cerrar sesión" }],
      bOpenMenu: false,
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.$store.commit("setOverlay", false);

    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      if (window.innerWidth < 599.99) {
        this.isMobile = true;
        this.isTable = false;
        this.isLap = false;
      } else if (window.innerWidth > 600 && window.innerWidth < 959.99) {
        this.isMobile = false;
        this.isTable = true;
        this.isLap = false;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263.99) {
        if (this.bSidebar) {
          this.$store.commit("setSidebar", false);
        }

        this.isMobile = false;
        this.isTable = false;
        this.isLap = true;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903.99) {
        if (this.bSidebar) {
          this.$store.commit("setSidebar", false);
        }

        this.isMobile = false;
        this.isTable = false;
        this.isLap = true;
      } else if (window.innerWidth > 1904) {
        if (this.bSidebar) {
          this.$store.commit("setSidebar", false);
        }

        this.isMobile = false;
        this.isTable = false;
        this.isLap = true;
      }
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    },
    goView(sView) {
      this.$store.commit("setSidebar", false);

      this.$router.push(`/home/${sView}`).catch(() => { });
    },
    Logout() {
      this.$store.commit("setOverlay", true);
      this.$store.commit("setSidebar", false);
      setTimeout(() => {
        this.$store.commit("setUserLogin", {
          sUserId: "",
          sName: "",
          sEmail: "",
          sToken: "",
        });
        this.$router.go();
      }, 2500);
    },
    openMenuResponsive() {
      let bOpentMenuTem = !this.bSidebar;
      this.$store.commit("setSidebar", bOpentMenuTem);
    },
  },
  computed: {
    bSidebar() {
      return this.$store.state.bSidebar;
    },
  },
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 4000);
    },
  },
};
</script>
<style scoped>
.contant-navbar {
  z-index: 1 !important;
}

.content-navbar-inside {
  display: flex;
  width: 100%;
}

.content-logo {
  width: 25%;
  max-width: 25%;
  min-width: 25%;
  align-self: center;
}

.content-text {
  width: 100%;
  display: flex;
  align-items: center;
  place-content: center;
}

.content-text-press-news {
  display: flex;
  width: 100%;
  position: relative;
  cursor: pointer;
  justify-content: flex-end;
}

.content-text-contact {
  display: flex;
  width: 100%;
  position: relative;
  cursor: pointer;
  justify-content: flex-start;
}

.item-not-selected::first-letter {
  color: var(--primary-color-text-item-not-selected) !important;
}

.item-not-selected {
  color: var(--primary-color-text-item-not-selected) !important;
}

.content-selected-item-press-news {
  position: absolute;
  background-color: #a3332c;
  height: 2px;
  width: 195px;
  bottom: -5px;
  right: 21px;
  border-radius: 20px;
}

.content-selected-item-contact {
  position: absolute;
  background-color: #a3332c;
  height: 2px;
  width: 85px;
  bottom: -5px;
  left: 21px;
  border-radius: 20px;
}

.content-profile {
  display: flex;
  width: 25%;
  max-width: 25%;
  min-width: 25%;
  align-self: center;
  text-align: end;
  place-content: flex-end;
}

.list-menu-profile .v-list-item .v-list-item__icon {
  margin-right: 5px;
  display: flex;
  place-self: center;
}

.content-item-text {
  display: flex;
  cursor: pointer;
}

.item-text::first-letter {
  margin-bottom: 0px !important;
  font-size: 22px;
  text-transform: uppercase;
  font-family: "GaraPro-Regular" !important;
  color: var(--primary-color-text);
}

.item-text {
  margin-bottom: 0px !important;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "GaraPro-Regular" !important;
  color: var(--primary-color-text);
}

.content-menu-profile {
  display: flex;
}

.text-and {
  margin-bottom: 0px !important;
  font-size: 22px;
  text-transform: uppercase;
  font-family: "GaraPro-Regular" !important;
  color: var(--primary-color-text);
  margin-top: 4px;
}

.divider-vertical-navbar {
  margin-bottom: 0px !important;
  font-size: 60px;
}

.item-not-selected {
  color: var(--primary-color-text-item-not-selected) !important;
}

.img-log-navbar {
  max-height: 55px !important;
  min-height: 55px !important;
  max-width: 270px !important;
  min-width: 270px !important;
  background: url("../assets/ImgsNavbar/logo.png") no-repeat center;
  background-size: cover;
  background-position: center center;
  background-origin: content-box;
}

.img-log-navbar-mobile {
  max-height: 55px !important;
  min-height: 55px !important;
  max-width: 55px !important;
  min-width: 55px !important;
  background: url("../assets/ImgsLogin/Icon-Login.png") no-repeat center;
  background-size: cover;
  background-position: center center;
  background-origin: content-box;
}

/* RESPONSIVE CSS */
/* XS Small to large phone*/
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
}

/* SM Small to medium tablet */
@media (min-width: 600px) and (max-width: 959.99px) {
  .demoColorResponsive {
    background-color: rgb(120, 136, 621);
  }
}

/* MD Large tablet to laptop 960 to 1185 */
@media (min-width: 960px) and (max-width: 1184.99px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }

  .content-logo {
    width: 25%;
    max-width: 25%;
    min-width: 25%;
    align-self: center;
  }

  .img-log-navbar {
    max-height: 40px !important;
    min-height: 40px !important;
    max-width: 200px !important;
    min-width: 200px !important;
    background: url("../assets/ImgsNavbar/logo.png") no-repeat center;
    background-size: cover;
    background-position: center center;
    background-origin: content-box;
  }

  .img-log-navbar-mobile {
    max-height: 55px !important;
    min-height: 55px !important;
    max-width: 55px !important;
    min-width: 55px !important;
    background: url("../assets/ImgsLogin/Icon-Login.png") no-repeat center;
    background-size: cover;
    background-position: center center;
    background-origin: content-box;
  }

  .content-text {
    width: 100%;
    display: flex;
    align-items: center;
    place-content: center;
  }

  .content-text-press-news {
    display: flex;
    width: 100%;
    position: relative;
    cursor: pointer;
    justify-content: flex-end;
  }

  .content-text-contact {
    display: flex;
    width: 100%;
    position: relative;
    cursor: pointer;
    justify-content: flex-start;
  }

  .text-capital-letter::first-letter {
    margin-bottom: 0px !important;
    font-size: 20px;
    text-transform: uppercase;
    font-family: "GaraPro-Regular" !important;
  }

  .text-capital-letter {
    margin-bottom: 0px !important;
    font-size: 16px;
    text-transform: uppercase;
    font-family: "GaraPro-Regular" !important;
    color: var(--primary-color-text);
  }

  .text-and {
    margin-bottom: 0px !important;
    font-size: 20px;
    text-transform: uppercase;
    font-family: "GaraPro-Regular" !important;
    color: var(--primary-color-text);
    margin-top: 0px;
  }

  .item-not-selected {
    color: var(--primary-color-text-item-not-selected) !important;
  }

  .content-selected-item-press-news {
    position: absolute;
    background-color: #a3332c;
    height: 2px;
    width: 160px;
    bottom: -5px;
    right: 21px;
    border-radius: 20px;
  }

  .content-selected-item-contact {
    position: absolute;
    background-color: #a3332c;
    height: 2px;
    width: 65px;
    bottom: -5px;
    left: 21px;
    border-radius: 20px;
  }

  .content-profile {
    display: flex;
    width: 25%;
    max-width: 25%;
    min-width: 25%;
    align-self: center;
    text-align: end;
    place-content: flex-end;
  }
}

@media (min-width: 1186px) and (max-width: 1264.99px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG Desktop */
@media (min-width: 1265px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL  4k and ultra-wide */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>