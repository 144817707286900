<template>
  <div class="content-search-contact">
    <div id="ContentPressNews" class="content-text-title-contact">
      {{ TextCapitalLetter("ContentPressNews", "Reseñas") }}
    </div>
    <div class="content-field-btn">
      <div class="content-flex-search">
        <div class="content-field-search">
          <p class="lbl-search">Búsqueda general</p>

          <v-text-field v-model="sSearchEdit" placeholder="Buscar por nombre de reseña" class="global-text-field global-search-text-field" dense filled
            append-icon="mdi-magnify"></v-text-field>
        </div>
      </div>
      <!-- <div class="content-flex-date">
        <div v-show="!isMobile" class="content-field-date">
          <p class="lbl-search">Buscar por fecha</p>

          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="sDateEdit"
            max-width="100%"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="sDateEdit"
                class="global-text-field global-search-text-field"
                append-icon="mdi-calendar"
                @click:append="menu = !menu"
                readonly
                dense
                filled
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="sDateEdit"
              locale="es"
              color="#A3332C"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="black" @click="menu = false"> Cancelar </v-btn>
              <v-btn text color="black" @click="$refs.menu.save(sDateEdit)">
                Aceptar
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div v-show="isMobile" class="content-btn-date-resposive">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="global-btn-primary global-btn-primary-search"
              >
                <v-icon> mdi-calendar </v-icon>
              </v-btn>
            </template>
            <v-date-picker
              v-model="sDateEdit"
              locale="es"
              color="#A3332C"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="black" @click=" sDateEdit = ''"> Cancelar </v-btn>
              <v-btn text color="black" @click="$refs.menu.save(sDateEdit)">
                Aceptar
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tab: Number
  },
  data() {
    return {
      isMobile: false,
      sSearchEdit: "",
      sDateEdit: "",
      menu: false,
      date: "",
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
      this.menu = false
    },
    TextCapitalLetter(idElement, text) {
      let aText = text.split(" ");
      let sText = " ";

      for (let i = 0; i < aText.length; i++) {
        const element = aText[i];
        sText += `<p
                class="${element.length > 1
            ? `text-capital-letter-title ${i < aText.length - 1 ? `mr-2` : ``
            }`
            : `text-only-one-letter-title ${i < aText.length - 1 ? `mr-2` : ``
            } `
          }"

              >
                ${element}
              </p>`;
      }
      this.$nextTick(() => {
        let element = document.getElementById(idElement);
        element.innerHTML = sText;
      });
      return sText;
    },
    sSearchChangeText(text) {
      this.$emit("sSearchChangeText", text);
    },
    sDateChangeText(text) {
      this.$emit("sDateChangeText", text);
    },
    addItem() {
      this.$router.push({ name: "PressAndNewsDetail", params: { id: "0" } });
    },
  },
  watch: {
    sSearchEdit() {
      this.sSearchChangeText(this.sSearchEdit);
    },
    sDateEdit() {
      this.sDateChangeText(this.sDateEdit);
    },
    tab() {
      this.sSearchEdit = "";
    }
  },
};
</script>
<style scoped>
.content-search-contact {
  display: flex !important;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin-top: 15px;
  margin-bottom: 25px;
}

.content-text-title-contact {
  display: flex;
  line-height: 1;
}

.content-field-search {
  margin-top: -13px;
  margin-left: 10px;

  width: 298px;
}

.content-field-date {
  margin-top: -13px;
  margin-left: 10px;
  width: 250px;
}

.lbl-search {
  margin-bottom: -10px;
  bottom: 45px;
  margin-left: 10px;
  color: var(--primary-color-text-label) !important;
  font-family: "GaraPro-Regular" !important;
  font-size: 18px;
}

.lbl-search-date {
  margin-bottom: -10px;
  bottom: 45px;
  margin-left: 10px;
  color: var(--primary-color-text-label) !important;
  font-family: "GaraPro-Regular" !important;
  font-size: 18px;
}

.content-field-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

/* RESPONSIVE CSS */

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-search-contact {
    display: block !important;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .content-text-title-contact {
    display: flex;
    margin-bottom: 25px;
  }

  .content-flex-search {
    display: flex;
    width: 100%;
  }

  .content-flex-date {
    display: flex;
    width: 100%;
  }

  .content-field-search {
    width: 100%;
  }

  .lbl-search {
    margin-left: 10px;
    font-family: "GaraPro-Regular" !important;
    font-size: 20px;
  }

  /* .lbl-search-date {
    margin-left: 10px;
    font-family: "GaraPro-Regular" !important;
    font-size: 20px;
  } */
  .content-field-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  .content-field-date {
    margin-left: 10px;
    width: auto;
  }

  .content-btn-date-resposive {
    margin-left: 10px;
  }

  .content-flex-date {
    width: auto;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(120, 136, 621);
  }

  .content-search-contact {
    display: block !important;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .content-text-title-contact {
    display: flex;
    margin-bottom: 20px;
  }

  .content-flex-search {
    display: flex;
    width: auto;
  }

  .content-flex-date {
    display: flex;
    width: 100%;
  }

  .content-field-search {
    display: block;
    width: 250px;
    margin-left: 0px;

  }

  .lbl-search {
    bottom: 35px;
    margin-left: 10px;
    font-family: "GaraPro-Regular" !important;
    font-size: 20px;
  }

  .lbl-search-date {
    bottom: 35px;
    margin-left: 10px;
    font-family: "GaraPro-Regular" !important;
    font-size: 20px;
  }

  .content-field-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  .content-field-date {
    margin-left: 10px;
    width: 232px;
  }
}

/* MD 960 to 1235  */
@media (min-width: 960px) and (max-width: 1235px) {
  .demoColorResponsive {
    background-color: rgb(120, 136, 621);
  }

  .content-search-contact {
    display: block !important;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .content-text-title-contact {
    margin-bottom: 20px;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>