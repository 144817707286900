<template>
  <div class="content-edit">
    <search-press-and-news-detail-component :sAction="sAction" />
    <div v-if="sAction === 'Nuevo'">
      <press-and-news-add-component />
    </div>
    <div v-else>
      <press-and-news-edit-component />
    </div>
  </div>
</template>
<script>
import PressAndNewsAddComponent from "./PressAndNewsAddComponent.vue";
import PressAndNewsEditComponent from "./PressAndNewsEditComponent.vue";
import SearchPressAndNewsDetailComponent from "./SearchPressAndNewsDetailComponent.vue";
export default {
  components: {
    SearchPressAndNewsDetailComponent,
    PressAndNewsAddComponent,
    PressAndNewsEditComponent,
  },
  data() {
    return {
      sAction: "",
    };
  },
  beforeMount() {
    if (this.$route.params.id === "0") {
      this.sAction = "Nuevo";
    } else {
      this.sAction = "Editar";
    }
  },
};
</script>
<style scoped>
.content-edit {
  /* background-color: red; */
  padding: 0px 200px !important;
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-edit {
    /* background-color: red; */
    padding: 0px 0px !important;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(120, 136, 621);
  }
  .content-edit {
    /* background-color: red; */
    padding: 0px 0px !important;
  }
}

/* MD Large tablet to laptop 960 to 1185 */
@media (min-width: 960px) and (max-width: 1184.99px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
  .content-edit {
    /* background-color: red; */
    padding: 0px 100px !important;
  }
}

/* MD */
@media (min-width: 1186px) and (max-width: 1264.99px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
