<template>
  <div>
    <v-expansion-panels class="contant-comments">
      <v-expansion-panel v-for="(item, i) in itemsReviews" :key="i">
        <v-expansion-panel-header
          :id="'Panel' + i"
          @click="viewComment(i, item)"
          :class="false ? 'content-header-panel-not-view' : ''"
        >
          <div class="content-header-panel">
            <div class="content-name-panel">
              {{ item.sName }}
            </div>
            <div class="content-email-panel">
              {{ item.sEmail }}
            </div>
            <div class="content-book-panel">
              <!-- {{ item.sNameContact }} -->
              {{ getNameBook(item.iBook) }}
            </div>
            <div class="content-date-panel">
              {{ item.created_at }}
            </div>
            <div class="content-actions-panel">
              <v-btn
                v-if="showActionApproved(item.iTab)"
                @click="dialogReviewsItem(item, true)"
                icon
                color="#6BCB76"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn
                v-if="showActionEdit(item.iTab)"
                @click="dialogEditReviewsItem(item)"
                icon
                color="#A3332C"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                v-if="showActionApproved(item.iTab)"
                @click="dialogReviewsItem(item, false)"
                icon
                color="#FF0F19"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn
                v-if="showActionDelete(item.iTab)"
                @click="dialogDeleteItem(item, false)"
                icon
                color="#FF0F19"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="content-description">
          {{ item.sMessage }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div
      v-if="itemsReviews.length === 0"
      class="content-empty-card"
      :style="{ height: iHeight + 'px' }"
    >
      <span class="txt-empty-card">"Sin información"</span>
    </div>
    <dialog-review-approved-global-component
      :bDialogReviews="bDialogReviews"
      @dialogReviewsItem="dialogReviewsItem"
      :itemReviews="itemReviews"
      :itemTextReviews="itemTextReviews"
    />
    <dialog-edit-review-global-component
      :bDialogEditReviews="bDialogEditReviews"
      @dialogEditReviewsItem="dialogEditReviewsItem"
      :itemReviews="itemReviews"
      :tab="tab"
    />
    <dialog-delete-global-component
      :bDialogDelete="bDialogDelete"
      @dialogDeleteItem="dialogDeleteItem"
      :itemDelete="itemReviews"
      :itemTextDelete="itemTextDelete"
    />
  </div>
</template>
<script>
import DialogReviewApprovedGlobalComponent from "../../global-components/DialogReviewApprovedGlobalComponent.vue";
import DialogEditReviewGlobalComponent from "../../global-components/DialogEditReviewGlobalComponent.vue";
import DialogDeleteGlobalComponent from "../../global-components/DialogDeleteGlobalComponent.vue";

export default {
  props: {
    itemsReviews: Array,
    tab: Number,
  },
  data() {
    return {
      iHeight: 0,
      itemsComments: [
        // {
        //     sNameContact: "Samantha Mejía López",
        //     sEmailContect: "samejia@gmail.com",
        //     sDescription:
        //         " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nunc metus, dapibus commodo augue ut, interdum molestie ligula. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed finibus justo maximus dui luctus aliquam quis eu arcu. Aenean ut metus metus. Donec iaculis nisl non mollis suscipit. Donec odio magna, sagittis quis justo id, facilisis vestibulum ex. Nulla mi turpis, pharetra id eleifend eu, tempor eget nisl. Aliquam euismod urna vel erat bibendum luctus. Praesent egestas ligula porta venenatis efficitur.",
        //     bViewComments: false,
        // },
        // {
        //     sNameContact: "Wendy Saveedra Quijano",
        //     sEmailContect: "wensaq@gmail.com",
        //     sDescription:
        //         " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nunc metus, dapibus commodo augue ut, interdum molestie ligula. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed finibus justo maximus dui luctus aliquam quis eu arcu. Aenean ut metus metus. Donec iaculis nisl non mollis suscipit. Donec odio magna, sagittis quis justo id, facilisis vestibulum ex. Nulla mi turpis, pharetra id eleifend eu, tempor eget nisl. Aliquam euismod urna vel erat bibendum luctus. Praesent egestas ligula porta venenatis efficitur.",
        //     bViewComments: true,
        // },
        // {
        //     sNameContact: "Javier Garza Guzmán",
        //     sEmailContect: "javier.garza.gus@gmail.com",
        //     sDescription:
        //         " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nunc metus, dapibus commodo augue ut, interdum molestie ligula. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed finibus justo maximus dui luctus aliquam quis eu arcu. Aenean ut metus metus. Donec iaculis nisl non mollis suscipit. Donec odio magna, sagittis quis justo id, facilisis vestibulum ex. Nulla mi turpis, pharetra id eleifend eu, tempor eget nisl. Aliquam euismod urna vel erat bibendum luctus. Praesent egestas ligula porta venenatis efficitur.",
        //     bViewComments: false,
        // },
        // {
        //     sNameContact: "Daniel Zarate Valencia",
        //     sEmailContect: "d.zarate@gmail.com",
        //     sDescription:
        //         " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nunc metus, dapibus commodo augue ut, interdum molestie ligula. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed finibus justo maximus dui luctus aliquam quis eu arcu. Aenean ut metus metus. Donec iaculis nisl non mollis suscipit. Donec odio magna, sagittis quis justo id, facilisis vestibulum ex. Nulla mi turpis, pharetra id eleifend eu, tempor eget nisl. Aliquam euismod urna vel erat bibendum luctus. Praesent egestas ligula porta venenatis efficitur.",
        //     bViewComments: true,
        // },
      ],
      bDialogReviews: false,
      bDialogEditReviews: false,
      bDialogDelete: false,
      itemReviews: {},
      itemTextReviews: {},
      itemTextDelete: {},
    };
  },
  beforeMount() {

  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.iHeight = window.innerHeight - 390;
    },
    viewComment(i, item) {
      if (!item.bViewComments) {
        item.bViewComments = true;
      }
    },
    dialogReviewsItem(item, action) {
      this.itemReviews = item;
      (this.itemTextReviews = {
        title: action ? "ACEPTAR RESEÑA" : "RECHAZAR RESEÑA",
        description: action
          ? "Estás a punto de aceptar una reseña, al aceptar, se publicará automáticamente en el sitio web y la reseña se irá a la pestaña de aceptadas."
          : "Estás a punto de rechazar una reseña, al rechazar, no se publicará en el sitio web y la reseña se irá a la pestaña de rechazados.",
        question: "¿Desea continuar con la acción?",
        bApproved: action,
      }),
        (this.bDialogReviews = !this.bDialogReviews);
    },
    dialogDeleteItem(item) {
      if (item !== undefined) {
        this.itemDelete = item;
        this.itemTextDelete = {
          title: "Eliminar reseña",
          description: "La eliminación de una reseña es una acción irreversible.",
          question: "¿Desea continuar con la acción?",
          Api: `${window.URI}/messages/${item.sMessageId}`,
        };
      }
      this.bDialogDelete = !this.bDialogDelete;
    },
    dialogEditReviewsItem(item) {
      this.itemReviews = item;
      this.bDialogEditReviews = !this.bDialogEditReviews;
    },
    showActionApproved(iTab) {
      return iTab == 0 ? true : false;
    },
    showActionEdit(iTab) {
      return iTab == 0 || iTab == 1 ? true : false;
    },
    showActionDelete(iTab) {
      return iTab == 1 || iTab == 2 ? true : false;
    },
    getNameBook(iBook) {
      switch (iBook) {
        case 1:
          return "El punto animado";
        case 2:
          return "Políticamente incorrecta Primer semestre";
        case 3:
          return "Políticamente incorrecta Segundo semestre";

        default:
          break;
      }
    },
  },
  watch: {},
  components: {
    DialogReviewApprovedGlobalComponent,
    DialogEditReviewGlobalComponent,
    DialogDeleteGlobalComponent,
  },
};
</script>
<style scoped>
.content-empty-card {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.txt-empty-card {
  margin-bottom: 0px !important;
  font-style: italic;
  font-size: 20px;
  text-transform: uppercase;
  font-family: "GaraPro-Regular" !important;
  color: var(--primary-color-text-navbar-grey) !important;
}

.contant-comments {
  margin-top: 20px;
  padding: 3px !important;
}

.content-header-panel {
  display: flex;
  width: 100%;
}

.v-expansion-panel .content-header-panel-not-view {
  border: solid 1px var(--primary-color-btn-primary);
  border-left: solid 5px var(--primary-color-btn-primary);
  padding: 16px 20px !important;
}

.content-name-panel {
  font-family: "GaraPro-Regular" !important;
  align-self: center;
  font-size: 20px;
  text-transform: none;
  width: 50%;
}

.content-email-panel {
  text-align: center;
  font-family: "GaraPro-Regular" !important;
  align-self: center;
  font-size: 20px;
  text-transform: none;
  width: 50%;
}

.content-book-panel {
  text-align: center;
  font-family: "GaraPro-Regular" !important;
  align-self: center;
  font-size: 20px;
  margin-left: 10px;
  text-transform: none;
  width: 50%;
}

.content-date-panel {
  text-align: center;
  font-family: "GaraPro-Regular" !important;
  align-self: center;
  font-size: 20px;
  text-transform: none;
  width: 40%;
}

.content-actions-panel {
  display: flex;
  font-family: "GaraPro-Regular" !important;
  align-self: center;
  font-size: 20px;
  text-transform: none;
  width: 110px;
  max-width: 110px;
  /* min-width: 110px; */
}

.content-description {
  font-family: "GaraPro-Regular" !important;
  font-size: 18px;
}

/* RESPONSIVE CSS */

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-header-panel {
    display: block;
    width: 100%;
  }

  .content-name-panel {
    font-family: "GaraPro-Regular" !important;
    text-align: left;
    font-size: 18px;
    text-transform: none;
    width: 100% !important;
    margin-bottom: 10px;
  }

  .content-email-panel {
    font-family: "GaraPro-Regular" !important;
    text-align: left;
    font-size: 16px;
    text-transform: none;
    width: 100%;
  }

  .content-book-panel {
    font-family: "GaraPro-Regular" !important;
    font-size: 16px;
    text-transform: none;
    width: 100%;
    text-align: left !important;
    margin-top: 5px;
  }

  .content-date-panel {
    font-family: "GaraPro-Regular" !important;
    font-size: 16px;
    text-transform: none;
    width: 50%;
    text-align: left;
    margin-left: 0px;
    margin-top: 5px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(120, 136, 621);
  }

  .content-name-panel {
    font-family: "GaraPro-Regular" !important;
    font-size: 16px;
    text-transform: none;
    width: 50%;
  }

  .content-email-panel {
    font-family: "GaraPro-Regular" !important;
    font-size: 16px;
    text-transform: none;
    width: 50%;
  }

  .content-email-panel {
    font-family: "GaraPro-Regular" !important;
    font-size: 16px;
    text-transform: none;
    width: 50%;
  }

  .content-book-panel {
    font-family: "GaraPro-Regular" !important;
    font-size: 16px;
    text-transform: none;
    width: 50%;
    text-align: left !important;
    margin-left: 5px;
    margin-top: 5px;
  }

  .content-date-panel {
    font-family: "GaraPro-Regular" !important;
    font-size: 16px;
    text-transform: none;
    width: 50%;
    text-align: left;
    margin-left: 0px;
    margin-top: 5px;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
