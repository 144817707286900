var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"contant-navbar",attrs:{"min-width":_vm.screenWidth,"max-width":_vm.screenWidth,"height":"70","max-height":"70","min-height":"70","color":"white"}},[_c('div',{staticClass:"content-navbar-inside"},[_c('div',{staticClass:"content-logo"},[_c('v-img',{class:_vm.isMobile ? 'img-log-navbar-mobile' : 'img-log-navbar',attrs:{"max-width":"270"}})],1),_c('div',{staticClass:"content-text"},[_c('div',{staticClass:"content-text-press-news",class:this.$route.name === 'PressAndNews' ? '' : 'item-not-selected',on:{"click":function($event){return _vm.goView('press-and-news')}}},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLap),expression:"isLap"}],staticClass:"text-capital-letter",class:this.$route.name === 'PressAndNews' || this.$route.name === 'PressAndNewsDetail' ? '' : 'item-not-selected'},[_vm._v(" Prensa ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLap),expression:"isLap"}],staticClass:"text-and ml-1 mr-1",class:this.$route.name === 'PressAndNews' || this.$route.name === 'PressAndNewsDetail' ? '' : 'item-not-selected'},[_vm._v(" & ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLap),expression:"isLap"}],staticClass:"text-capital-letter mr-5",class:this.$route.name === 'PressAndNews' || this.$route.name === 'PressAndNewsDetail' ? '' : 'item-not-selected'},[_vm._v(" Noticias ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLap),expression:"isLap"}],class:this.$route.name === 'PressAndNews' || this.$route.name === 'PressAndNewsDetail'
              ? 'content-selected-item-press-news'
              : ''})]),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLap),expression:"isLap"}],attrs:{"vertical":""}}),_c('div',{staticClass:"content-text-contact",on:{"click":function($event){return _vm.goView('contact')}}},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLap),expression:"isLap"}],staticClass:"text-capital-letter ml-5",class:this.$route.name === 'Contact' ? '' : 'item-not-selected'},[_vm._v(" Reseñas ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLap),expression:"isLap"}],class:this.$route.name === 'Contact'
              ? 'content-selected-item-contact'
              : ''})])],1),_c('div',{staticClass:"content-profile"},[_c('v-menu',{attrs:{"rounded":"xl","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.isTable || _vm.isLap),expression:"isTable || isLap"}],staticClass:"content-menu-profile",on:{"click":function($event){return _vm.$store.commit('setSidebar', false)}}},'div',attrs,false),on),[_c('v-avatar',[_c('img',{attrs:{"src":require("@/assets/ImgsNavbar/Img-Profile-2.png")}})]),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',{staticClass:"list-menu-profile"},_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.Logout()}}},[_c('v-list-item-icon',{staticClass:"item-icon"},[_c('v-icon',[_vm._v("mdi-power")])],1),_c('v-list-item-title',[_c('div',{staticClass:"content-item-text"},[_c('p',{staticClass:"item-text mr-2"},[_vm._v("Cerrar")]),_c('p',{staticClass:"item-text"},[_vm._v("Sesión")])])])],1)}),1)],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile || _vm.isTable),expression:"isMobile || isTable"}],attrs:{"icon":""},on:{"click":_vm.openMenuResponsive}},[_c('v-icon',[_vm._v("mdi-menu")])],1)],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile || _vm.isTable),expression:"isMobile || isTable"}]},[_c('navbar-responsive-component',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile || _vm.isTable),expression:"isMobile || isTable"}],attrs:{"bOpenMenu":_vm.bOpenMenu},on:{"Logout":_vm.Logout,"goView":_vm.goView}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }