import Vue from "vue";
import VueRouter from "vue-router";
import Store from "../store";
// import reroute from "../rerouter";

import router_guests from "../router_guest";
import router_users from "@/router_users";

Vue.use(VueRouter);

var allRoutes = [];
allRoutes = allRoutes.concat(router_guests, router_users);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: allRoutes,
});

router.beforeEach((to, from, next) => {
  if (to.name == "HomeLogin") {
    if (Store.state.sToken !== "") {
      next({
        path: "/home",
      });
    } else {
      next();
    }
  } else {
    if (Store.state.sToken !== "") {
      if (to.name) {
        next();
      }else{
        next({
          name:'PressAndNews'
        })
      }
      // if (Store.state.aPermissions === null) {
      //   next();
      // } else {
      //   if (
      //     Store.state.aPermissions
      //       .map((e) => e.sModuleId)
      //       .includes(to.meta.permission, 0)
      //   ) {
      //     next();
      //   } else {
      //     next({
      //       path: reroute.redirectUser(
      //         Store.state.aPermissions.map((e) => e.sModuleId)),
      //     });
      //   }
      // }
    } else {
      next({
        path: "/login",
      });
    }
  }
});

export default router;
