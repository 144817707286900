<template>
  <div class="content-press-news">
    <search-press-and-news-component @sSearchChangeText="sSearchChangeText" />
    <div>
      <v-row>
        <v-col v-for="(item, i) in item" :key="i" cols="12" sm="6" md="4" lg="3" xl="3">
          <v-card class="content-items-press-news">
            <v-card-title>
              <img v-if="item.sImageUrl" :src="item.sImageUrl" @click="accion(1, item)" alt=""
                class="img-item-press-new" />
              <img v-else src="/imgs/img-placeholder.png" @click="accion(1, item)" alt="" class="img-item-press-new" />
            </v-card-title>
            <v-card-title>
              <div class="content-text-title-and-action">
                <div class="content-text-title">
                  <p class="text-title-items">{{ item.sTitle }}</p>
                </div>
                <div class="content-btn-action">
                  <v-menu attach rounded="xl" left offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="" v-bind="attrs" v-on="on">
                        <v-icon color="black" class="btn-option-actions">mdi-dots-vertical</v-icon>
                      </div>
                    </template>
                    <v-list class="list-menu-accions" min-width="250px">
                      <v-list-item v-for="(itemAction, index) in itemsAccions" :key="index"
                        @click="accion(itemAction.accion, item)">
                        <v-list-item-icon class="item-icon">
                          <v-icon color="black">{{ itemAction.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          <div class="content-item-text">
                            <p class="text-item-accions">
                              {{ itemAction.title }}
                            </p>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
            </v-card-title>

            <v-card-subtitle class="content-text-description">
              <p v-if="item.sContent" class="text-description">
                {{ item.sContent }}
              </p>
              <p v-else class="text-description-empty text-center">
                sin información
              </p>
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <div v-if="item.length === 0" class="content-empty-card" :style="{ 'height': iHeight + 'px' }">
        <span class="txt-empty-card">Sin información</span>
      </div>
    </div>
    <dialog-delete-global-component :bDialogDelete="bDialogDelete" @dialogDeleteItem="dialogDeleteItem"
      :itemDelete="itemDelete" :itemTextDelete="itemTextDelete" />
  </div>
</template>
<script>
import DialogDeleteGlobalComponent from "../../global-components/DialogDeleteGlobalComponent.vue";
import SearchPressAndNewsComponent from "./SearchPressAndNewsComponent.vue";
export default {
  components: { SearchPressAndNewsComponent, DialogDeleteGlobalComponent },
  data() {
    return {
      iHeight: 0,
      show: false,
      itemsAccions: [
        { title: "Editar", accion: 1, icon: "mdi-pencil" },
        { title: "Eliminar", accion: 2, icon: "mdi-delete-forever" },
      ],
      item: [
        // require("@/assets/ImgsPressNews/img1.jpg"),
        // require("@/assets/ImgsPressNews/img2.jpg"),
        // require("@/assets/ImgsPressNews/img3.jpg"),
        // require("@/assets/ImgsPressNews/img4.jpg"),
        // require("@/assets/ImgsPressNews/img5.jpg"),
        // require("@/assets/ImgsPressNews/img6.jpg"),
        // require("@/assets/ImgsPressNews/img7.jpg"),
        // require("@/assets/ImgsPressNews/img8.jpg"),
      ],
      bDialogDelete: false,
      itemDelete: {},
      itemTextDelete: {},
      sSearch: "",
      iPageNumber: 1,
      iItemsPerPage: 8,
      iNumberPages: 5,
    };
  },
  beforeMount() {
    this.getPressAndNews();
  },
  beforeDestroy() {
    if (typeof window === 'undefined') return

    window.removeEventListener('resize', this.onResize, { passive: true })
  },

  mounted() {
    this.onResize()

    window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    onResize() {
      this.iHeight = window.innerHeight - 270
    },
    TextCapitalLetter(idElement, text) {
      let aText = text.split(" ");
      let sText = " ";

      for (let i = 0; i < aText.length; i++) {
        const element = aText[i];
        sText += `<p
                class="${element.length > 1
            ? `text-capital-letter-title ${i < aText.length - 1 ? `mr-2` : ``
            }`
            : `text-only-one-letter-title ${i < aText.length - 1 ? `mr-2` : ``
            } `
          }"

              >
                ${element}
              </p>`;
      }
      this.$nextTick(() => {
        let element = document.getElementById(idElement);
        element.innerHTML = sText;
      });
      return sText;
    },
    accion(accion, item) {
      switch (accion) {
        case 1:
          this.editItem(item.sArticleId);
          break;
        case 2:
          this.dialogDeleteItem(item);
          break;

        default:
          break;
      }
    },
    editItem(item) {
      this.$router.push({ name: "PressAndNewsDetail", params: { id: item } });
    },
    dialogDeleteItem(item) {
      // let contentRouter = document.getElementsByClassName("layout")
      // contentRouter[0].classList.add('global-content-overlay')
      // contentRouter[0].classList.toggle('global-content-overlay')
      if (item !== undefined) {
        this.itemDelete = item;
        this.itemTextDelete = {
          title: "Eliminar registro",
          description:
            "La eliminación de un registro es una acción irreversible.",
          question: "¿Desea continuar con la acción?",
          Api: `${window.URI}/articles/${item.sArticleId}`
        }
      }
      this.bDialogDelete = !this.bDialogDelete;
    },

    getPressAndNews() {
      let params = {
        // iPageNumber: this.iPageNumber,
        // iItemsPerPage: this.iItemsPerPage,
        sSearch: this.sSearch,
      };
      window.DB.get(`${window.URI}/articles`, {
        params,
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.item = response.data.articles;
          // this.numPages = response.data.numPages;
          // this.totalItems = response.data.total;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          // if (error.response.status == 401) {
          //   this.Error("Tu sesión ha finalizado.");
          //   const loading = this.$vs.loading({
          //     type: "circles",
          //     color: "#F1D72E",
          //   });
          //   setTimeout(() => {
          //     localStorage.clear();
          //     window.location.reload();
          //     loading.close();
          //   }, 3000);
          // }
          this.Error(error.response.data.code, error.response.data.message);
        });
    },
    //#region FUNCTION OF FOOTER TABLE //
    changeTable(e) {
      this.iItemsPerPage = e;
      this.iPageNumber = 1;
    },
    backPage() {
      if (this.iPageNumber > 1) {
        this.iPageNumber--;
        this.getPressAndNews();
      }
    },
    nextPage() {
      if (this.iPageNumber < this.iNumberPages) {
        this.iPageNumber++;
        this.getPressAndNews();
      }
    },
    firstPage() {
      this.iPageNumber = 1;
    },
    lastPage() {
      this.iPageNumber = this.iNumberPages;
    },
    //#endregion FUNCTION OF FOOTER TABLE //
    sSearchChangeText(text) {
      this.sSearch = text;
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    sSearch() {
      this.iPageNumber = 1;
      this.getPressAndNews();
    },
    refreshTable() {
      this.getPressAndNews();
    },
  },
};
</script>
<style scoped>
.content-empty-card {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.txt-empty-card {
  margin-bottom: 0px !important;
  font-style: italic;
  font-size: 20px;
  text-transform: uppercase;
  font-family: "GaraPro-Regular" !important;
  color: var(--primary-color-text-navbar-grey) !important;
}

.content-press-news {
  margin-bottom: 30px !important;
}

.content-items-press-news {
  height: 320px;
  transition: all .5s;
}

/* .content-items-press-news:hover {
  opacity: 0.5;
  box-shadow: 0px 3px 6px #00000029;

} */

/* .content-items-press-news :hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 0.5;
} */


.content-text-title-press-news {
  display: flex;
}

.content-text-title-and-action {
  display: flex;
  width: 94%;
  max-width: 94%;
  min-width: 94%;
}

.text-title-items {
  font-family: "GaraPro-Regular" !important;
  font-size: 18px;
  margin-bottom: 0px;
}

.text-description {
  font-family: "GaraPro-Regular" !important;
  font-size: 14px;
  margin-bottom: 0px;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

}

.img-item-press-new {
  width: 100%;
  height: 150px;
  cursor: pointer;
  border-radius: 3px;
  object-fit: cover;
}

.content-text-title {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

.btn-option-actions {
  width: 25px;
  display: flex;
  cursor: pointer;
}

.text-item-accions::first-letter {
  margin-bottom: 0px !important;
  font-size: 20px;
  text-transform: uppercase;
  font-family: "GaraPro-Regular" !important;
  color: var(--primary-color-text-black);
}

.text-item-accions {
  margin-bottom: 0px !important;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "GaraPro-Regular" !important;
  color: var(--primary-color-text-black);
}

.list-menu-accions .v-list-item .v-list-item__icon {
  margin-right: 5px;
  display: flex;
  place-self: center;
}

.content-footer-page-items {
  margin-top: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.content-texts {
  font-family: "GaraPro-Regular" !important;
  font-size: 13px;
}

.content-buttons {
  margin-left: 15px;
}

.btn-chevron-left {
  margin-right: 15px;
}

/* RESPONSIVE CSS */

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .img-item-press-new {
    width: 100%;
    height: 163px;
  }

  .img-item-press-new img {
    width: 100%;
    height: auto;
  }

  @supports (object-fit: cover) {
    .img-item-press-new img {
      height: 100%;
      object-fit: cover;
      object-position: center center;
      border-radius: 10px;
    }
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(120, 136, 621);
  }
  .content-items-press-news {
    height: 340px;
    transition: all .5s;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
  .content-items-press-news {
    height: 350px;
    transition: all .5s;
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }

  .content-items-press-news {
    height: 350px;
    transition: all .5s;
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>