<template>
    <div>
        <contact-component/>
    </div>
</template>
<script>
import ContactComponent from '../components/contact/ContactComponent.vue'
export default {
  components: { ContactComponent },
    data() {
      return{

      }  
    },
}
</script>
