<template>
    <div>
        <v-dialog class="content-global-dialog" persistent @keydown.esc="closeDialogReview" v-model="bDialogEditReviews"
            :width="screenWidth">
            <v-card class="global-container-dialog">
                <v-btn @click="closeDialogReview" icon class="global-btn-close">
                    <v-icon> mdi-close </v-icon>
                </v-btn>

                <v-card-title>
                    <div id="ContentTitleDelete" class="content-title-edit-review">
                        {{ TextCapitalLetter("ContentTitleDelete", "EDITAR RESEÑA") }}
                    </div>
                </v-card-title>

                <v-card-text>
                    <div class="content-field-title">
                        <p class="lbl-title-edit">Nombre</p>
                        <v-text-field v-model="sName" class="global-text-field" placeholder="Escribir Nombre" dense
                            filled>
                        </v-text-field>
                    </div>
                    <div class="content-field-title">
                        <p class="lbl-title-disabled">Correo electrónico</p>
                        <p class="lbl-txt">{{ sEmail }}</p>

                    </div>
                    <div class="content-field-title">
                        <p class="lbl-title-edit">Puesto</p>
                        <v-text-field v-model="sJobDescription" class="global-text-field" placeholder="Escribir puesto" dense
                            filled>
                        </v-text-field>
                    </div>
                    <div class="content-field-title">
                        <p class="lbl-title-disabled">Libro</p>
                        <v-select v-model="iBook" :items="aBook" :clearable="sBook !== null ? true : false" item-value="iValue" item-text="sName"
                            class="global-text-field" filled></v-select>

                    </div>
                    <div class="content-field-title">
                        <p class="lbl-title-disabled">Fecha</p>
                        <p class="lbl-txt">{{ sDate }}</p>
                    </div>
                    <div class="content-field-title">
                        <p class="lbl-title-edit">Comentario</p>
                        <v-textarea v-model="sDescription" filled auto-grow placeholder="Comentario…"
                            class="global-text-field text-area-description"></v-textarea>
                    </div>
                </v-card-text>

                <v-card-text>
                    <div class="content-btns-accions">
                        <div class="content-btn-second">
                            <v-btn @click="closeDialogReview" class="global-btn-neutral">
                                Cancelar
                            </v-btn>
                        </div>

                        <v-spacer></v-spacer>
                        <div class="content-btn-primary">
                            <v-btn :loading="bLoading" class="global-btn-primary" @click="setEdit()">
                                Aceptar
                            </v-btn>
                        </div>
                        <v-spacer v-if="tab == 0"></v-spacer>
                        <div v-if="tab == 0" class="content-btn-approved">
                            <div class="content-btn-aut">
                                <v-btn :loading="bLoading" class="global-btn-authorize btn-aut "
                                    @click="setApproved(true)">
                                    <v-icon>mdi-check</v-icon>
                                </v-btn>
                            </div>
                            <div class="content-btn-reje">
                                <v-btn :loading="bLoading" class="global-btn-reject btn-reje"
                                    @click="setApproved(false)">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    props: {
        bDialogEditReviews: Boolean,
        itemReviews: Object,
        tab: Number
    },
    data() {
        return {
            screenWidth: 0,
            bLoading: false,
            sMessageId: "",
            sName: "",
            sEmail: "",
            aBook: [{ sName: "Selecciona un libro:", iValue: null, disabled: true }, { sName: "El punto animado", iValue: 1 }, { sName: "Políticamente incorrecta Primer semestre", iValue: 2 }, { sName: "Políticamente incorrecta Segundo semestre", iValue: 3 }],
            sBook: null,
            iBook: 0,
            sDate: "",
            sDescription: "",
            sJobDescription: ""
        };
    },
    beforeMount() {
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    beforeDestroy() {
        this.closeDialogReview();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            // size to range
            if (window.innerWidth < 599) {
                this.screenWidth = "100%";
            } else {
                this.screenWidth = "550px";
            }
        },
        closeDialogReview() {
            let contentRouter = document.getElementsByClassName("layout");
            contentRouter[0].classList.remove("global-content-overlay");

            this.$emit("dialogEditReviewsItem");
        },
        TextCapitalLetter(idElement, text) {
            if (text) {
                let aText = text.split(" ");
                let sText = " ";

                for (let i = 0; i < aText.length; i++) {
                    const element = aText[i];
                    sText += `<p
                      class="${element.length > 1
                            ? `text-capital-letter ${i < aText.length - 1 ? `mr-2` : ``
                            }`
                            : `text-only-one-letter ${i < aText.length - 1 ? `mr-2` : ``
                            } `
                        }"
      
                    >
                      ${element}
                    </p>`;
                }
                if (this.bDialogEditReviews) {
                    this.$nextTick(() => {
                        let element = document.getElementById(idElement);
                        element.innerHTML = sText;
                    });
                }
                return sText;

            }
        },
        setEdit() {
            this.bLoading = true;
            const payload = {
                sName: this.sName,
                sMessage: this.sDescription,
                sEmail: this.sEmail,
                iBook: this.iBook,
                sJobDescription: this.sJobDescription
            },
                config = {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.sToken,
                    },
                };
            window.DB.put(
                `${window.URI}/messages/${this.sMessageId}`,
                payload,
                config
            )
                .then((response) => {
                    this.bLoading = false;
                    this.$store.commit("refresher", true);
                    this.closeDialogReview();
                    this.Success(response.data.messageSuccess);
                })
                .catch((error) => {
                    this.bLoading = false;
                    this.Error(error.response.data.code, error.response.data.message);
                });
        },
        setApproved(bApproved) {
            this.bLoading = true;
            const payload = {
                bApproved: bApproved
            }, config = {
                headers: {
                    Authorization: "Bearer " + this.$store.state.sToken,
                },
            };
            window.DB.patch(`${window.URI}/messages/${this.itemReviews.sMessageId}`, payload, config)
                .then((response) => {
                    this.bLoading = false;
                    this.closeDialogReview()
                    this.$store.commit("refresher", true);
                    this.Success(response.data.messageSuccess)

                })
                .catch((error) => {
                    this.bLoading = false;
                    this.Error(error.response.data.code, error.response.data.message);
                });
        },
        getNameBook(iBook) {
            switch (iBook) {
                case 1:
                    return "El punto animado";
                case 2:
                    return "Políticamente incorrecta Primer semestre";
                case 3:
                    return "Políticamente incorrecta Segundo semestre";

                default:
                    break;
            }
        }
    },
    watch: {
        bDialogEditReviews() {
            if (this.bDialogEditReviews) {
                let contentRouter = document.getElementsByClassName("layout");
                contentRouter[0].classList.add("global-content-overlay");
                this.sMessageId = this.itemReviews.sMessageId;
                this.sName = this.itemReviews.sName;
                this.sEmail = this.itemReviews.sEmail;
                this.sBook = this.getNameBook(this.itemReviews.iBook);
                this.iBook = this.itemReviews.iBook;
                this.sDate = this.itemReviews.created_at;
                this.sDescription = this.itemReviews.sMessage;
                this.sJobDescription = this.itemReviews.sJobDescription;

            }
        },
    },
};
</script>
<style scoped>
.content-title-edit-review {
    display: flex;
    place-content: center;
    width: 100%;
}

.lbl-title-edit {
    font-family: "GaraPro-Regular" !important;
    font-size: 17px;
    color: var(--primary-color-text-black);
    margin-bottom: 0px;

}

.lbl-txt {
    font-family: "GaraPro-Regular" !important;
    font-size: 17px;
    color: var(--primary-color-text-black);

}

.lbl-title-disabled {
    font-family: "GaraPro-Regular" !important;
    font-size: 17px;
    color: var(--primary-color-btn-primary);
    margin-bottom: 0px;
}

.content-field-title {
    margin-bottom: 10px;
}

.content-btns-accions {
    display: flex;
    width: 100% !important;
}

.content-btn-primary {
    width: 100%;
}

.content-btn-approved {
    display: flex;
    width: 100%;
    margin-left: 15px;

}

.content-btn-aut {
    width: 50%;
}

.content-btn-reje {
    margin-left: 30px;
    width: 50%;
}

.content-btn-second {
    width: 100%;
    margin-right: 15px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
    .demoColorResponsive {
        background-color: rgb(190, 136, 226);
    }

    .content-btns-accions {
        display: block;
        width: 100% !important;
    }

    .content-btn-second {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 15px;
    }

    .content-btn-approved {
        display: flex;
        width: 100%;
        margin-left: 0px;
        margin-top: 15px;

    }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
    .demoColorResponsive {
        background-color: rgb(136, 226, 151);
    }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
    .demoColorResponsive {
        background-color: rgb(201, 122, 83);
    }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(204, 198, 109);
    }
}

/* XL */
@media (min-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(113, 199, 201);
    }
}
</style>