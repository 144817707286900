import Vue from "vue";
import Vuex from "vuex";
import PersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    aPermissions: null,
    sUserId: "",
    sNameFull: "",
    sEmail: "",
    sToken: "",

    refresh: false,

    bSidebar: false,
    bOverlay: false,
  },
  mutations: {
    setUserLogin(state, item) {
      state.sUserId = item.sUserId;
      state.sNameFull = item.sName;
      state.sEmail = item.sEmail;
      state.sToken = item.sToken;
      state.bAuth = item.bAuth;
    },
    setSidebar(state, item) {
      state.bSidebar = item;
    },
    setOverlay(state, item) {
      state.bOverlay = item;
    },
    refresher(state, item) {
      state.refresh = item;
    },
  },
  actions: {},
  modules: {},
  plugins: [PersistedState()],
});
