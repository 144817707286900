<template>
  <div class="content-contact">
    <search-contact-component @sSearchChangeText="sSearchChangeText" @sDateChangeText="sDateChangeText" :tab="tab" />
    <div>
      <v-tabs v-model="tab" justify-tab-title="start" background-color="transparent" color="black"
        next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline" show-arrows>
        <v-tabs-slider color="#A3332C"></v-tabs-slider>

        <v-tab v-for="(item, i) in itemsTab" :key="i">
          {{ item.text }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, i) in itemsTab" :key="i">
          <reviews-component :itemsReviews="itemsReviews" :tab="tab" />
          <!-- <keep-alive>
            <component
              :sSearch="sSearch"
              :sDate="sDate"
              v-bind:is="item.component"
            ></component>
          </keep-alive> -->
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>

import ReviewsComponent from "./ReviewsComponent.vue";
import SearchContactComponent from "./SearchContactComponent.vue";

export default {
  components: {
    ReviewsComponent,
    SearchContactComponent,
  },
  beforeMount() {

  },
  data() {
    return {
      sSearch: "",
      sDate: "",
      tab: null,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      itemsTab: [
        { text: "Solicitudes" },
        { text: "Aprobadas" },
        { text: "Rechazadas" },
      ],
      itemsReviews: []
    };
  },
  methods: {
    sSearchChangeText(text) {
      this.sSearch = text;
    },
    sDateChangeText(text) {
      this.sDate = text;
    },
    getReseñas() {
      let params = {
        sSearch: this.sSearch,
        bApproved: this.getApproved()
      };
      window.DB.get(`${window.URI}/messages/admin`, {
        params,
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.itemsReviews = response.data.messages.map((e) => {
            return {
              ...e,
              iTab: this.tab
            }
          })
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.Error(error.response.data.code, error.response.data.message);
        });
    },
    getApproved() {
      switch (this.tab) {
        case 0:
          return null;
        case 1:
          return true;
        case 2:
          return false;
        default:
          break;
      }
    }
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    tab() {
      this.getReseñas();
    },
    sSearch() {
      this.getReseñas();
    },
    refreshTable() {
      this.getReseñas();
    }
  },
};
</script>
<style scoped>
.content-contact {
  margin-bottom: 30px !important;
}

.content-text-title-contact {
  display: flex;
}

.text-tabs {
  font-family: "GaraPro-Regular" !important;
  font-size: 20px;
  text-transform: none;
}

/* RESPONSIVE CSS */

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(120, 136, 621);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>