<template>
  <div class="content-edit-press-and-news">
    <div id="container" class="content-img">
      <div v-if="dataImg !== null" @mouseover="hoverDraggImg = true" @mouseout="hoverDraggImg = false"
        class="content-image">
        <v-btn @click="removeImg" icon class="button-delete-item">
          <v-icon size="20px"> mdi-delete </v-icon>
        </v-btn>
        <span v-show="hoverDraggImg" class="hover-dragg-img">Arrastrar imagen para reposicionar</span>
        <img id="item" :src="maskedFile(dataImg)" alt="" class="img-edit-press-and-news" />
      </div>
      <v-btn v-else id="btnAddImg" @click="$refs.inputUpload.click()" @dragover="dragover" @dragleave="dragleave"
        @drop="drop" class="button-add-img">
        <div>
          <v-icon class="content-icon-add-img" color="#B2B2B2" size="40px">
            mdi-image-plus
          </v-icon>
          <p class="text-img-add">Arrastra ó da clic para añadir</p>
          <p class="text-img-add">imagen de Banner</p>
          <p class="text-img-add">500px x 1920px</p>
        </div>
        <input ref="inputUpload" style="display: none" type="file" size="60" accept=".jpg, .jpeg, .png" multiple="false"
          @change="uploadImg" />
      </v-btn>
    </div>
    <div class="content-texts-fields">
      <div class="content-field-title-date">
        <div class="content-field-title">
          <p class="lbl-title">Título</p>
          <v-text-field v-model="sTitle" class="global-text-field" placeholder="Escribir título…" dense filled>
          </v-text-field>
        </div>
        <div class="content-field-date">
          <p class="lbl-date">Fecha de publicación</p>
          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" max-width="100%"
            transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="date" class="global-text-field" append-icon="mdi-calendar"
                @click:append="menu = !menu" readonly dense filled placeholder="Seleccionar fecha…" clearable
                v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="date" locale="es" color="#A3332C" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="black" @click="menu = false"> Cancelar </v-btn>
              <v-btn text color="black" @click="$refs.menu.save(date)">
                Aceptar
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </div>
      <div class="content-field-description">
        <p class="lbl-description">Descripción</p>
        <div>
          <v-textarea v-model="sDescription" filled auto-grow placeholder="Descripción…"
            class="global-text-field text-area-description"></v-textarea>
        </div>
      </div>
    </div>
    <div class="content-btns-accions">
      <div class="content-btn-cancel">
        <v-btn class="global-btn-neutral" @click="goView('press-and-news')"> Cancelar </v-btn>
      </div>
      <div class="content-btn-save-change">
        <v-btn class="global-btn-primary" :loading="bLoading" @click="setSave" :disabled="!bValidateForm">Guardar
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hoverDraggImg: false,
      dragItem: null,
      container: null,
      active: false,
      currentX: 0,
      currentY: 0,
      initialX: 0,
      initialY: 0,
      xOffset: 0,
      yOffset: 0,
      dataImg: null,
      sImgEdit: null,
      menu: false,
      sTitle: "",
      date: "",
      sDescription: "",
      bLoading: false
    };
  },
  updated() {
    // this.getValuesItemDragg();
  },
  beforeMount() {
    // this.sImgEdit = this.$route.params.id;
  },
  beforeDestroy() {
    window.removeEventListener("touchstart", this.dragStart, false);
    window.removeEventListener("touchend", this.dragEnd, false);
    window.removeEventListener("touchmove", this.drag, false);
    window.removeEventListener("mousedown", this.dragStart, false);
    window.removeEventListener("mouseup", this.dragEnd, false);
    window.removeEventListener("mousemove", this.drag, false);
  },
  mounted() {
    window.addEventListener("touchstart", this.dragStart, false);
    window.addEventListener("touchend", this.dragEnd, false);
    window.addEventListener("touchmove", this.drag, false);
    window.addEventListener("mousedown", this.dragStart, false);
    window.addEventListener("mouseup", this.dragEnd, false);
    window.addEventListener("mousemove", this.drag, false);
  },
  methods: {
    goView(sView) {
      this.$store.commit("setSidebar", false);

      this.$router.push(`/home/${sView}`).catch(() => { });
    },
    discardChange() {
      this.dataImg = null;
      this.sTitle = "";
      this.date = "";
      this.sDescription = "";
    },
    dragStart(e) {
      let imgCssPosition = document.getElementById("item");
      if (imgCssPosition !== null) {
        imgCssPosition.style.cursor = "grabbing";
      }
      if (e.type === "touchstart") {
        this.initialX = e.touches[0].clientX - this.xOffset;
        this.initialY = e.touches[0].clientY - this.yOffset;
      } else {
        this.initialX = e.clientX - this.xOffset;
        this.initialY = e.clientY - this.yOffset;
      }

      if (e.target === this.dragItem) {
        // let imgCssPosition = document.getElementById("item");
        // imgCssPosition.style.cursor = "grabbing";
        this.active = true;
      }
    },
    dragEnd() {
      this.initialX = this.currentX;
      this.initialY = this.currentY;
      let imgCssPosition = document.getElementById("item");
      if (imgCssPosition !== null) {
        imgCssPosition.style.cursor = "move";
      }
      this.active = false;
    },
    drag(e) {
      if (this.active) {
        e.preventDefault();
        // let imgCssPosition = document.getElementById("item");
        // imgCssPosition.style.cursor = "";
        // imgCssPosition.style.cursor = "grabbing";

        if (e.type === "touchmove") {
          this.currentX = e.touches[0].clientX - this.initialX;
          this.currentY = e.touches[0].clientY - this.initialY;
        } else {
          this.currentX = e.clientX - this.initialX;
          this.currentY = e.clientY - this.initialY;
        }
        this.xOffset = this.currentX;
        this.yOffset = this.currentY;

        if (
          this.yOffset > 0 &&
          this.yOffset < 100 &&
          this.xOffset > 0 &&
          this.xOffset < 100
        ) {
          this.setTranslate(this.currentY, this.currentX);
        } else {
          if (this.yOffset > 100) {
            this.currentY = 100;
            this.yOffset = 100;
          } else {
            this.currentY = 0;
            this.yOffset = 0;
          }
          if (this.xOffset > 100) {
            this.currentX = 100;
            this.xOffset = 100;
          } else {
            this.currentX = 0;
            this.xOffset = 0;
          }
        }
      }
    },
    setTranslate(yPos, xPos) {
      let imgCssPosition = document.getElementById("item");
      if (yPos > 0 && yPos < 100 && xPos > 0 && xPos < 100) {
        imgCssPosition.style.objectPosition = `${xPos}% ${yPos}%`;
      }
    },
    getValuesItemDragg() {
      this.dragItem = document.querySelector("#item");
      this.container = document.querySelector("#container");
    },
    uploadImg: function (e) {
      this.dataImg = null;
      this.sImgEdit = null;

      const files = e.target.files;
      this.dataImg = files;
      setTimeout(() => {
        this.getValuesItemDragg();
      }, 500);
    },
    editImg: function () {
      this.$refs.inputUpload.click();
    },
    removeImg: function () {
      this.dataImg = null;
    },
    maskedFile: function (file) {
      return URL.createObjectURL(file[0]);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.dataImg = event.dataTransfer.files;
      // this.validateForm();

      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    setSave() {
      this.bLoading = true;
      const payload = {
        sTitle: this.sTitle,
        tPublishDate: this.date,
        sContent: this.sDescription,
      },
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        };
      window.DB.post(
        `${window.URI}/articles`,
        payload,
        config
      )
        .then((response) => {
          // this.Success(response.data.message);
          this.addImg(response.data.article.sArticleId,response.data.message)
        })
        .catch((error) => {
          this.bLoading = false;
          this.Error(error.response.data.code, error.response.data.message);
        });
    },
    addImg(id, Message,) {
      let form = new FormData();
      form.append("aPicture", this.dataImg[0]);
      const config = {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      };
      window.DB.patch(`${window.URI}/articles/${id}`, form, config)
        .then(() => {
          this.bLoading = false;
          this.$store.commit("refresher", true);
          this.Success(Message);
          this.$router.push({name:"PressAndNews"})
        })
        .catch((error) => {
          this.bLoading = false;
          this.Error(error.response.data.code, error.response.data.message);
        });
    },

  },
  computed: {
    bValidateForm() {
      return (this.dataImg !== null && this.dataImg !== "") && this.sTitle.trim() !== "" && this.sDescription.trim() !== ""
    }
  }
};
</script>
<style scoped>
.content-edit {
  padding: 5px 180px;
}

.content-edit-press-and-news {
  margin-top: 15px;
}

/*#region content-img */
.content-img {
  margin-bottom: 20px;
  justify-content: center;
  display: flex;
  width: 100%;
}

.content-image {
  width: 100%;
  height: 292px !important;
  max-height: 292px !important;
  min-height: 292px !important;
  display: flex;

  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid #70707080;
  border-radius: 0px;
}

.button-delete-item {
  position: absolute;
  right: 15px;
  top: 5px;
  min-width: 30px !important;
  height: 35px !important;
  background-color: var(--primary-color-btn-primary) !important;
  padding: 0px !important;
  border-radius: 10px;
  color: var(--primary-color-text-white) !important;
}

.hover-dragg-img {
  position: absolute;
  text-align: center;
  z-index: 1;
  font-family: "GaraPro-Regular" !important;
  font-size: 20px;
  width: auto !important;
  height: auto !important;
  background-color: var(--primary-color-text-placeholder-move-img) !important;
  padding: 10px !important;
  border-radius: 10px;
  color: var(--primary-color-text-white) !important;
}

.content-backgorund {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.img-edit-press-and-news {
  cursor: move;

  height: 292px;
  width: 100%;
  object-fit: cover;
  border: var(--primary-color-border-text-field) solid 3px;
}

.button-add-img {
  width: 100%;
  height: 292px !important;
  background: var(--primary-color-backgorund-add-img) !important;
  border: 1px dashed var(--primary-color-border-grey);
  border-radius: 0px;
  opacity: 1;
  box-shadow: none !important;
}

.content-icon-add-img {
  margin-bottom: 15px;
}

.mdi-image-plus {
  font-size: 66px !important;
}

.text-img-add {
  color: var(--primary-color-text-placeholder-add-img) !important;
  font-family: "GaraPro-Regular" !important;
  width: 100%;
  text-transform: capitalize;
  font-size: 15px;
  margin-bottom: 0px;
}

/*#endregion content-img */

/*#region content-texts-fields */

.content-field-title-date {
  display: flex;
  width: 100%;
  align-items: center;
}

.content-field-title {
  width: 75%;
}

.lbl-title {
  margin-left: 15px;
  margin-bottom: 0px;
  color: var(--primary-color-text-label) !important;
  font-family: "GaraPro-Regular" !important;
  font-size: 20px;
}

.content-field-date {
  width: 35%;
  margin-left: 15px;
}

.lbl-date {
  margin-left: 15px;
  margin-bottom: 0px;
  color: var(--primary-color-text-label) !important;
  font-family: "GaraPro-Regular" !important;
  font-size: 20px;
}

.content-field-description {
  margin-top: 20px;
}

.lbl-description {
  margin-left: 15px;
  margin-bottom: 0px;
  color: var(--primary-color-text-label) !important;

  font-family: "GaraPro-Regular" !important;
  font-size: 20px;
}

/*#endregion content-texts-fields */

/*#region content-btns-accions */
.content-btns-accions {
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: end;
  justify-content: flex-end;
}

.content-btn-cancel {
  width: 210px;
}

.content-btn-save-change {
  width: 210px;
  margin-left: 15px;
  margin-bottom: 20px;
}

/*#endregion content-btns-accions */

/*#region RESPONSIVE CSS */
/* XS Small to large phone*/
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-edit {
    padding: 5px 0px;
  }

  .content-texts-fields {
    padding: 5px 20px;
  }

  .content-btns-accions {
    padding: 5px 20px;
  }

  .content-field-title-date {
    display: block;
    width: 100%;
    align-items: center;
  }

  .content-field-title {
    width: 100%;
  }

  .content-field-date {
    width: 100%;
    margin-top: 20px;

    margin-left: 0px;
  }

  .content-field-description {
    margin-top: 20px;
  }

  .content-btns-accions {
    margin-top: 20px;
    display: block;
    width: 100%;
    justify-content: end;
  }

  .content-btn-cancel {
    width: 100%;
    margin-bottom: 20px;
  }

  .content-btn-save-change {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 20px;
  }
}

/* SM Small to medium tablet 600 to 750 */
@media (min-width: 600px) and (max-width: 749.99px) {
  .demoColorResponsive {
    background-color: rgb(120, 136, 621);
  }

  .content-edit {
    padding: 5px 0px;
  }

  .content-field-title {
    width: 60%;
  }

  .content-field-date {
    width: 40%;
    margin-left: 15px;
  }
}

/* SM Small to medium tablet 750 to 960 */
@media (min-width: 750px) and (max-width: 959.99px) {
  .demoColorResponsive {
    background-color: rgb(120, 136, 621);
  }

  .content-edit {
    padding: 5px 0px;
  }
}

/* MD Large tablet to laptop 960 to 1185 */
@media (min-width: 960px) and (max-width: 1184.99px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }

  .content-edit {
    padding: 5px 130px;
  }

  .content-field-title {
    width: 70%;
  }

  .content-field-date {
    width: 40%;
    margin-left: 15px;
  }
}

/* MD Large tablet to laptop 1184.99 to 1264.99 */
@media (min-width: 1186px) and (max-width: 1264.99px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG Desktop */
@media (min-width: 1265px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL  4k and ultra-wide */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion RESPONSIVE CSS */
</style>