<template>
  <div>
    <v-dialog class="content-global-dialog" persistent @keydown.esc="closeDialogReview" v-model="bDialogReviews"
      :width="screenWidth">
      <v-card class="global-container-dialog">
        <v-btn @click="closeDialogReview" icon class="global-btn-close">
          <v-icon> mdi-close </v-icon>
        </v-btn>

        <v-card-title>
          <div id="ContentTitleDelete" class="content-title-delete">
            {{ TextCapitalLetter("ContentTitleDelete", itemTextReviews.title) }}
          </div>
        </v-card-title>

        <v-card-text>
          <div class="content-description-delete">
            <p class="description-delete">
              {{ itemTextReviews.description }}
            </p>
          </div>
          <div class="content-question-delete">
            <p class="question-delete">
              {{ itemTextReviews.question }}
            </p>
          </div>
        </v-card-text>

        <v-card-text>
          <div class="content-btns-accions">
            <div class="content-btn-second">
              <v-btn @click="closeDialogReview" class="global-btn-neutral">
                Cancelar
              </v-btn>
            </div>

            <v-spacer></v-spacer>
            <div class="content-btn-primary">
              <v-btn :loading="bLoading" class="global-btn-primary" @click="approvedItem()">
                Aceptar
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
export default {
  props: {
    bDialogReviews: Boolean,
    itemReviews: Object,
    itemTextReviews: Object,
  },
  data() {
    return {
      screenWidth: 0,
      bLoading: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    this.closeDialogReview();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      // size to range
      if (window.innerWidth < 599) {
        this.screenWidth = "100%";
      } else {
        this.screenWidth = "550px";
      }
    },
    closeDialogReview() {
      let contentRouter = document.getElementsByClassName("layout");
      contentRouter[0].classList.remove("global-content-overlay");

      this.$emit("dialogReviewsItem");
    },
    approvedItem() {
      this.bLoading = true;
      const payload = {
        bApproved:this.itemTextReviews.bApproved

      } , config = {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      };
      window.DB.patch(`${window.URI}/messages/${this.itemReviews.sMessageId}`, payload, config)
        .then((response) => {
          this.bLoading = false;
          this.closeDialogReview()
          this.$store.commit("refresher", true);
          this.Success(response.data.messageSuccess)

        })
        .catch((error) => {
          this.bLoading = false;
          this.Error(error.response.data.code, error.response.data.message);
        });
    },
    TextCapitalLetter(idElement, text) {
      if (text) {
        let aText = text.split(" ");
        let sText = " ";

        for (let i = 0; i < aText.length; i++) {
          const element = aText[i];
          sText += `<p
                    class="${element.length > 1
              ? `text-capital-letter ${i < aText.length - 1 ? `mr-2` : ``
              }`
              : `text-only-one-letter ${i < aText.length - 1 ? `mr-2` : ``
              } `
            }"
    
                  >
                    ${element}
                  </p>`;
        }
        if (this.bDialogReviews) {
          this.$nextTick(() => {
            let element = document.getElementById(idElement);
            element.innerHTML = sText;
          });
        }
        return sText;

      }
    },
  },
  watch: {
    bDialogReviews() {
      if (this.bDialogReviews) {
        let contentRouter = document.getElementsByClassName("layout");
        contentRouter[0].classList.add("global-content-overlay");
      }
    },
  },
};
</script>
  <style scoped>
  .content-title-delete {
    display: flex;
    place-content: center;
    width: 100%;
  }
  
  .content-description-delete {
    width: 100%;
  }
  
  .title-delete {
    color: var(--primary-color-text) !important;
    font-family: "pop-SemiBold";
    font-size: 25px;
    text-align: center;
  }
  
  .description-delete {
    color: var(--primary-color-text-description) !important;
    font-family: "pop-Regular";
    font-size: 16px;
    text-align: center;
    margin-bottom: 0px;
  }
  
  .content-question-delete {
    width: 100%;
    justify-self: center;
    display: flex;
    justify-content: center;
  }
  
  .question-delete {
    color: var(--primary-color-text-description) !important;
    margin-bottom: 0px;
    font-family: "pop-Regular";
    font-size: 16px;
    text-align: start;
    letter-spacing: 0px;
  }
  
  .content-btns-accions {
    display: flex;
    width: 100% !important;
  }
  
  .content-btn-primary {
    width: 100%;
  }
  
  .content-btn-second {
    width: 100%;
    margin-right: 15px;
  }
  
  /**************************MODO RESPONSIVO ********************/
  /* XS */
  @media (max-width: 599px) {
    .demoColorResponsive {
      background-color: rgb(190, 136, 226);
    }
  
    .content-btns-accions {
      display: block;
      width: 100% !important;
    }
  
    .content-btn-second {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 15px;
    }
  }
  
  /* SM */
  @media (min-width: 600px) and (max-width: 959px) {
    .demoColorResponsive {
      background-color: rgb(136, 226, 151);
    }
  }
  
  /* MD */
  @media (min-width: 960px) and (max-width: 1264px) {
    .demoColorResponsive {
      background-color: rgb(201, 122, 83);
    }
  }
  
  /* LG */
  @media (min-width: 1264px) and (max-width: 1904px) {
    .demoColorResponsive {
      background-color: rgb(204, 198, 109);
    }
  }
  
  /* XL */
  @media (min-width: 1904px) {
    .demoColorResponsive {
      background-color: rgb(113, 199, 201);
    }
  }
  </style>