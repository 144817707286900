<template>
    <div>
        <press-and-news-detail-component/>
    </div>
</template>
<script>
import PressAndNewsDetailComponent from '../components/press_and_news_detail/PressAndNewsDetailComponent.vue'
export default {
  components: { PressAndNewsDetailComponent },
    data() {
      return{

      }  
    },
}
</script>
<style scoped>

</style>