<template>
  <div id="MenuResponsive" class="content-menu-responsive">
    <div
      @click="goView('press-and-news')"
      class="content-text-press-news-responsive"
    >
      <p class="text-capital-letter text-color-grey-navbar mr-2">Prensa</p>
      <p class="text-and text-color-grey-navbar">&</p>
      <p class="text-capital-letter text-color-grey-navbar ml-2">Noticias</p>
    </div>
    <v-divider></v-divider>
    <div @click="goView('contact')" class="content-text-contact-responsive">
      <p class="text-capital-letter text-color-grey-navbar">Reseñas</p>
    </div>
    <v-divider class="divider-logout" v-show="isMobile"></v-divider>
    <div @click="Logout()" v-show="isMobile" class="content-text-logout-responsive">
      <v-icon v-show="isMobile"  class="icon-logout">mdi-power</v-icon>
      <p
        v-show="isMobile"
        class="text-capital-letter text-color-grey-navbar mr-2"
      >
        Cerrar
      </p>
      <p v-show="isMobile" class="text-capital-letter text-color-grey-navbar">
        Sesión
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bOpenMenu: Boolean,
  },
  data() {
    return {
      isMobile: false,
      isTable: false,
      isLap: false,
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  created() {
    this.openMenu(this.bSidebar);
  },
  beforeMount() {},
  methods: {
    onResize() {
      if (window.innerWidth < 599.99) {
        this.isMobile = true;
        this.isTable = false;
        this.isLap = false;
      } else if (window.innerWidth > 600 && window.innerWidth < 959.99) {
        this.isMobile = false;
        this.isTable = true;
        this.isLap = false;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263.99) {
        this.isMobile = false;
        this.isTable = false;
        this.isLap = true;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903.99) {
        this.isMobile = false;
        this.isTable = false;
        this.isLap = true;
      } else if (window.innerWidth > 1904) {
        this.isMobile = false;
        this.isTable = false;
        this.isLap = true;
      }
      //   this.screenWidth = window.innerWidth;
      //   this.screenHeight = window.innerHeight;
    },
    openMenu(bSidebar) {
      this.$nextTick(() => {
        if (bSidebar) {
          var MenuResponsive1 = document.getElementById("MenuResponsive");
          MenuResponsive1.classList.add("content-menu-responsive-open");
        } else {
          var MenuResponsive2 = document.getElementById("MenuResponsive");
          MenuResponsive2.classList.remove("content-menu-responsive-open");
        }
      });
    },
    goView(sView) {
 
      this.$emit('goView',sView)

    },
    Logout(){
      this.$emit('Logout')
    }
  },
  computed: {
    bSidebar() {
      return this.$store.state.bSidebar;
    },
  },
  watch: {
    bSidebar() {
      this.openMenu(this.bSidebar);
    },
  },
};
</script>
<style  scoped>
.content-menu-responsive {
  height: 0px;
  overflow: hidden;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  padding: 0px 0px 0px 0px;
  text-align: center;
  position: absolute !important;
  width: 100% !important;
  transition: 0.2s;
  background-color: white;
  z-index: 1;
}
.content-menu-responsive-open {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  height: auto !important;
  padding: 67px 20px 42px 20px !important;
  text-align: center;
  transition: 0.2s;
  position: absolute !important;
  width: 100% !important;
  background-color: white;
  z-index: 1;
}
.content-text-press-news-responsive {
  /* padding: 27px 27px 27px 27px; */
  display: flex !important;
  place-content: center;
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  margin-bottom: 20px;
}
.content-text-contact-responsive {
  /* padding: 27px 27px 27px 27px; */
  margin-top: 20px;
  cursor: pointer;

  /* margin-bottom: 20px; */
}
.content-text-logout-responsive {
  /* padding: 27px 27px 27px 27px; */
  display: flex !important;
  cursor: pointer;
  place-content: center;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  margin-top: 20px;
}
.text-color-grey-navbar::first-letter {
  color: var(--primary-color-text-navbar-grey) !important;
}
.text-color-grey-navbar {
  color: var(--primary-color-text-navbar-grey) !important;
}
.divider-logout{
  border-color: var(--primary-color-text-black) !important;
}
.icon-logout{
  color: var(--primary-color-text-navbar-grey) !important;

}
.text-and {
  margin-bottom: 0px !important;
  font-size: 22px;
  text-transform: uppercase;
  font-family: "GaraPro-Regular" !important;
  color: var(--primary-color-text);
  margin-top: 4px;
}

/* RESPONSIVE CSS */
.demoColorResponsive {
  position: absolute;
  height: 1vh;
  width: 10vh;
  background-color: rgb(0, 0, 0);
  bottom: 0px;
  right: 50%;
  left: 50%;
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-text-contact-responsive {
    /* padding: 27px 27px 27px 27px; */
    margin-top: 20px;
    cursor: pointer;

    margin-bottom: 20px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(120, 136, 621);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
