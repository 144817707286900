
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/Home.vue";
import PressAndNewsView from "@/views/PressAndNews.vue";
import PressAndNewsDetailView from "@/views/PressAndNewsDetail.vue";
import ContactView from "@/views/Contact.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    redirect:'/home/press-and-news',
    component: HomeView,
    children: [
      {
        path: "press-and-news",
        name: "PressAndNews",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "1",
        },
        component: PressAndNewsView,
      },
      {
        path: "press-and-news-detail/:id",
        name: "PressAndNewsDetail",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "1",
        },
        component: PressAndNewsDetailView,
      },
      {
        path: "contact",
        name: "Contact",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "2",
        },
        component: ContactView,
      }
    ],
  },
];

export default routes;
