export const notification = {
  methods: {
    Success(
      textTitle = "",
      textDescription = "",
      position = "top-right",
      duration = null,
      color = "success"
    ) {
      this.$vs.notification({
        color,
        duration,
        position,
        title: textTitle,
        text: textDescription,
      });
    },
    Error(
      iCodeError = 0,
      textTitle = "",
      textDescription = "",
      position = "top-right",
      duration = null,
      color = "danger"
    ) {
      this.$vs.notification({
        duration,
        color,
        position,
        title: textTitle,
        text: textDescription,
      });
      if (iCodeError === 401) {
        this.$store.commit("setOverlay", true);
        this.$store.commit("setSidebar", false);
        setTimeout(() => {
          this.$store.commit("setUserLogin", {
            sUserId: "",
            sName: "",
            sEmail: "",
            sToken: "",
          });
          this.$router.go();
        }, 2500);
      }
    },
    Warning(
      textTitle = "",
      textDescription = "",
      position = "top-right",
      color = "warn",
      duration = null
    ) {
      this.$vs.notification({
        duration,
        color,
        position,
        title: textTitle,
        text: textDescription,
      });
    },
    Info(
      textTitle = "",
      textDescription = "",
      position = "top-right",
      color = "primary",
      duration = null
    ) {
      this.$vs.notification({
        duration,
        color,
        position,
        title: textTitle,
        text: textDescription,
      });
    },
  },
};
