import { render, staticRenderFns } from "./PressAndNewsDetail.vue?vue&type=template&id=4400d4fc&scoped=true&"
import script from "./PressAndNewsDetail.vue?vue&type=script&lang=js&"
export * from "./PressAndNewsDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4400d4fc",
  null
  
)

export default component.exports