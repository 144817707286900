<template>
  <div class="content-search-press-new">
    <div id="ContentPressNews" class="content-text-title-press-news">
      {{ TextCapitalLetter("ContentPressNews", "Prensa & Noticias") }}
    </div>
    <div class="content-field-btn">
      <div class="content-flex-search">
        <div class="content-field-search">
          <p class="lbl-search">Búsqueda general</p>

          <v-text-field
            v-model="sSearchEdit"
            class="global-text-field global-search-text-field"
            placeholder="Buscar por nombre de prensa o noticia"
            dense
            filled
            append-icon="mdi-magnify"
          ></v-text-field>
        </div>
      </div>
      <div class="content-btn-add-press-new">
        <v-btn @click="addItem()" class="global-btn-primary global-btn-primary-search" dense>
          {{ isMobile ? "+" : "+ Añadir Registro" }}</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isMobile: false,
      sSearchEdit: "",
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    TextCapitalLetter(idElement, text) {
      let aText = text.split(" ");
      let sText = " ";

      for (let i = 0; i < aText.length; i++) {
        const element = aText[i];
        sText += `<p
                class="${
                  element.length > 1
                    ? `text-capital-letter-title ${
                        i < aText.length - 1 ? `mr-2` : ``
                      }`
                    : `text-only-one-letter-title ${
                        i < aText.length - 1 ? `mr-2` : ``
                      } `
                }"

              >
                ${element}
              </p>`;
      }
      this.$nextTick(() => {
        let element = document.getElementById(idElement);
        element.innerHTML = sText;
      });
      return sText;
    },
    sSearchChangeText(text) {
      this.$emit("sSearchChangeText", text);
    },
    addItem(){
      this.$router.push({ name: 'PressAndNewsDetail', params: { id: '0' } })
    }
  },
  watch: {
    sSearchEdit() {
      this.sSearchChangeText(this.sSearchEdit);
    },
  },
};
</script>
<style scoped>
.content-search-press-new {
  display: flex !important;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin-top: 15px;
  margin-bottom: 25px;
}
.content-text-title-press-news {
  display: flex;
  line-height: 1;
}
.content-field-search {
  margin-top: -13px;
  width: 298px;
}
.lbl-search {
  margin-bottom: -10px;
  bottom: 45px;
  margin-left: 10px;
  color: var(--primary-color-text-label) !important;
  font-family: "GaraPro-Regular" !important;
  font-size: 18px;
}
.content-field-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.content-btn-add-press-new {
  margin-left: 10px;
  width: 232px;
}

/* RESPONSIVE CSS */

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-search-press-new {
    display: block !important;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .content-text-title-press-news {
    display: flex;
    margin-bottom: 25px;
  }
  .content-flex-search {
    display: flex;
    width: 100%;
  }
  .content-field-search {
    width: 100%;
  }
  .lbl-search {
    margin-left: 10px;
    font-family: "GaraPro-Regular" !important;
    font-size: 20px;
  }
  .content-field-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  .content-btn-add-press-new {
    margin-left: 10px;
    width: auto;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(120, 136, 621);
  }
  .content-search-press-new {
    display: block !important;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .content-text-title-press-news {
    display: flex;
    margin-bottom: 20px;
  }
  .content-flex-search {
    display: flex;
    width: 100%;
  }
  .content-field-search {
    display: block;
    width: 275px;
  }
  .lbl-search {
    bottom: 35px;
    margin-left: 10px;
    font-family: "GaraPro-Regular" !important;
    font-size: 20px;
  }
  .content-field-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  .content-btn-add-press-new {
    margin-left: 10px;
    width: 232px;
  }
}
/* MD 960 to 1235  */
@media (min-width: 960px) and (max-width: 1235px) {
  .demoColorResponsive {
    background-color: rgb(120, 136, 621);
  }
  .content-search-press-new {
    display: block !important;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .content-text-title-press-news{
    margin-bottom: 20px;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>